import React from 'react';

import { TwitterShareButton, FacebookShareButton } from 'react-share';

const Share = ({ url, title, twitterHandle }) => {
  return (
    <div className="flex justify-center text-gray-600 mx-auto">
      <span className="mx-1">Share article</span>
      <TwitterShareButton
        className="mx-1 link"
        url={url}
        quote={title}
        via={twitterHandle.split('@').join('')}
      >
        Twitter
      </TwitterShareButton>
      <FacebookShareButton
        className="mx-1 link"
        url={url}
        quote={title}
        via={twitterHandle.split('@').join('')}
      >
        Facebook
      </FacebookShareButton>
    </div>
  );
};

export default Share;
