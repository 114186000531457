import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import GatsbyLink from 'gatsby-link';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Seo from 'components/seo/Seo';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Share from '../components/Share';
import config from '../../config/website';
import Container from '../components/Container';
import get from 'lodash.get';
import MutedPanel from '../components/MutedPanel';

const Categories = ({ categories }) => {
  return (
    <div className="items-center justify-center flex flex-wrap">
      {categories.map(cat => {
        return (
          <Link
            key={cat}
            className="px-4 py-2 border rounded mx-2"
            to={`/?category=${cat}`}
          >
            {cat}
          </Link>
        );
      })}
    </div>
  );
};

const Dependencies = ({ dependencies, className }) => {
  return (
    <MutedPanel header="Dependencies" className={className}>
      <div className="grid grid-auto-60 text-gray-600">
        {dependencies.map(dep => {
          return (
            <div className="mb-1" key={dep.name}>
              <Link to={dep.repo}>{dep.name}</Link> {dep.version}
            </div>
          );
        })}
      </div>
    </MutedPanel>
  );
};

export default function Post({
  data: { site, mdx },
  pageContext: { next, prev },
}) {
  const { frontmatter, fields, tableOfContents, headings } = mdx;
  const {
    date,
    title,
    banner,
    hideBanner,
    dependencies,
    categories,
    codeUrl,
    author = config.author,
    authorUrl = config.authorUrl,
  } = frontmatter;
  if (process.env.NODE_ENV === 'development') {
    console.log(
      '[dev] frontmatter = ',
      mdx.frontmatter,
      '\nfields = ',
      fields,
      '\ntableOfContents = ',
      tableOfContents,
      '\nheadings = ',
      headings,
      '\nmdx =',
      mdx,
      '\nsite =',
      site
    );
  }

  return (
    <Layout site={site} frontmatter={mdx.frontmatter}>
      <div id="top" className="pb-16">
        <Seo
          frontmatter={mdx.frontmatter}
          isBlogPost
          postImage={get(mdx, 'frontmatter.banner.childImageSharp.fluid.src')}
        />
        <article className="mb-16">
          <GatsbyLink to={`/${fields.slug}`}>
            <h1 className="text-4xl sm:text-6xl px-8 font-black leading-none sm:mt-32 mt-16 mb-6 text-center">
              {title}
            </h1>
          </GatsbyLink>
          <div className="sm:mb-16 mb-12">
            <div className="text-gray-600 justify-center text-center sm:text-xl text-lg flex sm:mb-16 mb-6 flex-col sm:flex-row">
              {author && (
                <h3>
                  By <Link to={authorUrl}>{author}</Link>
                </h3>
              )}
              {author && date && (
                <span className="text-gray-300 mx-4 sm:block hidden"> • </span>
              )}
              {date && <h3>{date}</h3>}
            </div>
            {dependencies && (
              <Container text className="mb-4">
                <Dependencies dependencies={dependencies} />
              </Container>
            )}
            {codeUrl && (
              <Container text className="mb-4 text-center">
                <Link to={codeUrl}>View Code on GitHub</Link>
              </Container>
            )}
            {false && (
              <div className="mb-4">
                <Categories categories={categories} />
              </div>
            )}
          </div>
          {!hideBanner && banner && (
            <Container text className="mb-12">
              <Img
                sizes={banner.childImageSharp.fluid}
                alt={site.siteMetadata.keywords.join(', ')}
              />
            </Container>
          )}

          <div className="mx-auto max-w-6xl leading-relaxed font-serif sm:text-lg">
            <MDXRenderer
              slug={fields.slug}
              tableOfContents={tableOfContents && tableOfContents.items}
            >
              {mdx.body}
            </MDXRenderer>
            <Container text className="px-4 max-w-3xl mx-auto mb-8">
              You can <Link to={codeUrl}>view all the code for this post</Link>{' '}
              on GitHub.
            </Container>
          </div>
          {/* <SubscribeForm /> */}
        </article>
        <Share
          url={`${config.siteUrl}/${mdx.frontmatter.slug}/`}
          title={title}
          twitterHandle={config.twitterHandle}
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...site
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        authorUrl
        description
        codeUrl
        hideBanner
        banner {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        dependencies {
          name
          version
          repo
        }
        categories
        slug
        keywords
      }
      headings {
        value
        depth
      }
      tableOfContents
      body
    }
  }
`;
